import React from 'react'

export const LogoTwitter = function ({ color }) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40">
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM10.4915 15.9285C9.90125 14.0069 10.7339 12.3946 10.8925 12.1011V12.0955C12.584 14.1974 14.8444 15.7402 17.3895 16.5302C18.3755 16.8302 19.3922 17.0114 20.4191 17.0701C20.3362 16.6696 20.0731 15.1615 20.9165 13.6498C21.3227 12.9291 21.9048 12.329 22.6057 11.9083C23.3067 11.4876 24.1024 11.2608 24.9148 11.2501C26.8486 11.2345 28.0948 12.4924 28.3291 12.7379C29.3726 12.5437 30.3711 12.1498 31.2722 11.5769C30.955 12.6678 30.2566 13.6009 29.3104 14.198C30.2075 14.1239 31.0802 13.8625 31.875 13.4301C31.2726 14.4291 30.4856 15.2981 29.5582 15.9885C29.6538 18.222 29.209 20.4451 28.2633 22.46C25.9123 27.4235 21.3617 29.082 20.6768 29.3192C14.8781 31.3248 10.0175 28.3012 9.375 27.8859C10.8349 28.0597 12.3143 27.8759 13.6914 27.3497C14.583 27.005 15.4167 26.5199 16.1622 25.9118C15.7594 25.8989 14.2924 25.8029 13.0813 24.6252C12.5243 24.0785 12.1112 23.3966 11.8801 22.6428C12.5466 22.7481 13.2272 22.7104 13.8788 22.532C12.8339 22.3077 11.8985 21.7162 11.2345 20.86C10.5705 20.0038 10.2196 18.9367 10.2428 17.8435C10.8359 18.211 11.5208 18.3929 12.2135 18.3668C11.4028 17.7604 10.8001 16.9069 10.4915 15.9285Z"
        fill={color}
      />
    </svg>
  )
}