import React from 'react'
import ReactDOM from 'react-dom'
import { CustomSnackbar } from './Snackbar'
let count = 0
let prevMessage = ''
export const snackbarShowMessage = (message, severity = 'error') => {
  if (!message || prevMessage === message) {
    return
  }

  let container = document.createElement('div')
  document.body.appendChild(container)

  function handleClose() {
    if (container) {
      ReactDOM.unmountComponentAtNode(container)
      document.body.removeChild(container)
      container = null
      count--
      prevMessage = ''
    }
  }

  ReactDOM.render(
    <CustomSnackbar message={message} severity={severity} onClose={handleClose} count={count} />,
    container
  )
  prevMessage = message
  count++
}
