import classNames from 'classnames'
import styles from './index.module.scss'

export const ApplicationIcon = (props) => (
  <svg className={classNames(styles.svg, props.className)} viewBox="0 0 22 22" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 5C3 3.89543 3.89543 3 5 3H17C18.1046 3 19 3.89543 19 5V15C19 16.1046 18.1046 17 17 17H6.37574C5.89129 17 5.42331 17.1758 5.05873 17.4948L3 19.2962V5ZM5 1C2.79086 1 1 2.79086 1 5V19.2962C1 21.0145 3.02384 21.9329 4.31701 20.8014L6.37574 19H17C19.2091 19 21 17.2091 21 15V5C21 2.79086 19.2091 1 17 1H5ZM11 6.5C10.2023 6.5 9.5 7.20226 9.5 8C9.5 8.55228 9.05228 9 8.5 9C7.94772 9 7.5 8.55228 7.5 8C7.5 6.09769 9.09769 4.5 11 4.5C12.9023 4.5 14.5 6.09769 14.5 8C14.5 9.5563 13.4307 10.9088 12 11.3492L12 11.5001C11.9999 12.0524 11.5522 12.5001 10.9999 12.5C10.4476 12.4999 9.99994 12.0522 10 11.4999L10.0001 10.4999C10.0002 9.94765 10.4479 9.5 11.0001 9.5C11.7978 9.5 12.5 8.7978 12.5 8C12.5 7.20226 11.7977 6.5 11 6.5ZM12 14.5C12 15.0523 11.5523 15.5 11 15.5C10.4477 15.5 10 15.0523 10 14.5C10 13.9477 10.4477 13.5 11 13.5C11.5523 13.5 12 13.9477 12 14.5Z"
      fill="currentColor"
    />
  </svg>
)
