import classNames from 'classnames'
import styles from './index.module.scss'

export const SmallLogoIcon = ({ className, color = '#0355BF' }) => (
  <svg
    className={classNames(styles.svg, className)}
    width="49"
    height="16"
    viewBox="0 0 49 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.7316 7.95565C46.9597 7.2002 45.8318 6.70395 44.3475 6.46643L41.1507 5.93323C40.5039 5.82974 40.0375 5.67057 39.7505 5.45571C39.464 5.24085 39.3208 4.94074 39.3208 4.55538C39.3208 4.14094 39.5447 3.81126 39.9935 3.56684C40.4413 3.32241 41.0475 3.2002 41.8122 3.2002C42.6203 3.2002 43.4103 3.34853 44.1823 3.6442C44.9538 3.94086 45.7438 4.40754 46.5519 5.04472L48.5583 2.2225C47.7204 1.54096 46.7172 0.99988 45.5487 0.600225C44.3803 0.200078 43.2231 0 42.0767 0C40.1069 0 38.5606 0.433662 37.4361 1.29999C36.3117 2.16681 35.7495 3.34853 35.7495 4.84415C35.7495 5.98546 36.0873 6.92621 36.7634 7.66687C37.4395 8.40754 38.4022 8.889 39.6518 9.11125L43.0466 9.71098C43.8107 9.84452 44.3583 10.0298 44.6893 10.2668C45.0198 10.5039 45.185 10.837 45.185 11.2667C45.185 11.7408 44.9391 12.1109 44.4468 12.378C43.954 12.6446 43.2744 12.7776 42.4076 12.7776C41.5257 12.7776 40.6799 12.6076 39.8723 12.2666C39.0636 11.9261 38.1963 11.3776 37.2704 10.6221L35.154 13.4C36.1093 14.2151 37.201 14.8523 38.4281 15.3111C39.6552 15.7704 40.8862 16 42.1207 16C44.2077 16 45.8577 15.5407 47.0702 14.6221C48.2826 13.7036 48.8888 12.4593 48.8888 10.8888C48.8888 9.6888 48.5031 8.7111 47.7316 7.95565Z"
      fill={color}
    />
    <path
      d="M13.4331 7.79993C13.9621 7.0297 14.2267 6.15548 14.2267 5.17778C14.2267 4.4519 14.0907 3.78564 13.8189 3.17754C13.5468 2.57042 13.172 2.04855 12.6945 1.61095C12.2167 1.17433 11.6436 0.833319 10.9749 0.588894C10.306 0.344468 9.57508 0.222259 8.78127 0.222259H0V15.7778H3.79197V3.48898H8.42864C9.01643 3.48898 9.49026 3.65209 9.85067 3.97783C10.2106 4.30406 10.3908 4.72589 10.3908 5.24431C10.3908 5.76322 10.2106 6.18555 9.85067 6.51128C9.49026 6.83702 9.01643 7.00013 8.42864 7.00013H5.97109L10.4789 15.7778H14.7118L11.3388 9.55575C12.2057 9.15561 12.9039 8.57066 13.4331 7.79993Z"
      fill={color}
    />
    <path d="M29.7118 11.3362L22.3779 7.80338V11.7935L30.6494 15.7778H33.2831V0.222259H29.7118V11.3362Z" fill={color} />
    <path d="M27.6642 4.20649L19.3926 0.222259H16.7591V15.7778H20.3305V4.6638L27.6642 8.19663V4.20649Z" fill={color} />
  </svg>
)
