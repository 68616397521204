import classNames from 'classnames'
import styles from './index.module.scss'

export const EditPhotoIcon = (props) => (
  <svg className={classNames(styles.svg, props.className)} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="12" fill="black" fillOpacity="0.6" />
    <path d="M11 29.0001H14.75L25.81 17.9401L22.06 14.1901L11 25.2501V29.0001ZM13 26.0801L22.06 17.0201L22.98 17.9401L13.92 27.0001H13V26.0801Z" fill="white" />
    <path d="M26.3699 11.2901C25.9799 10.9001 25.3499 10.9001 24.9599 11.2901L23.1299 13.1201L26.8799 16.8701L28.7099 15.0401C29.0999 14.6501 29.0999 14.0201 28.7099 13.6301L26.3699 11.2901Z" fill="white" />
  </svg>

)