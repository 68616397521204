import classNames from 'classnames'
import styles from './index.module.scss'

export const BurgerMenuIcon = (props) => (
  <svg className={classNames(styles.svg, props.className)} viewBox="0 0 40 40" fill="none">
    <rect width="40" height="40" rx="12" fill="#FAFAFA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 23H12C11.45 23 11 22.55 11 22C11 21.45 11.45 21 12 21H18C18.55 21 19 21.45 19 22C19 22.55 18.55 23 18 23ZM18 27H12C11.45 27 11 26.55 11 26C11 25.45 11.45 25 12 25H18C18.55 25 19 25.45 19 26C19 26.55 18.55 27 18 27ZM18 19H12C11.45 19 11 18.55 11 18C11 17.45 11.45 17 12 17H18C18.55 17 19 17.45 19 18C19 18.55 18.55 19 18 19ZM12 15C11.45 15 11 14.55 11 14C11 13.45 11.45 13 12 13H18C18.55 13 19 13.45 19 14C19 14.55 18.55 15 18 15H12ZM28 13H22C21.45 13 21 13.45 21 14V26C21 26.55 21.45 27 22 27H28C28.55 27 29 26.55 29 26V14C29 13.45 28.55 13 28 13ZM27 15H23V25H27V15Z"
      fill="#0355BF"
    />
  </svg>
)
