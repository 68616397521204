import classNames from 'classnames'
import styles from './index.module.scss'

export const SearchIcon = (props) => (
  <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9363 16.8871C12.2574 16.8871 13.4926 16.4405 14.4877 15.7018L17.1728 18.3902C17.5091 18.7269 18.0547 18.7269 18.3909 18.3902C18.7272 18.0535 18.7264 17.5079 18.3893 17.1721L15.7059 14.4994C16.4436 13.5031 16.8897 12.2834 16.8897 10.9436C16.8897 7.66258 14.2304 5 10.9534 5C7.67647 5 5 7.67975 5 10.9436C5 14.2074 7.67647 16.8871 10.9363 16.8871ZM11.0375 6.75C13.4078 6.75 15.325 8.60849 15.325 10.9062C15.325 13.204 13.4078 15.0625 11.0375 15.0625C8.66718 15.0625 6.75 13.204 6.75 10.9062C6.75 8.60849 8.6846 6.75 11.0375 6.75Z"
      fill="#69788C"
    />
  </svg>
)
