import classNames from 'classnames'
import styles from './index.module.scss'

export const LeftArrowIcon = (props) => (
  <svg
    className={classNames(styles.svg, props.className)}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 13.15C9.63333 13.2833 9.80433 13.3457 10.013 13.337C10.221 13.329 10.3917 13.2583 10.525 13.125C10.6583 12.975 10.725 12.7957 10.725 12.587C10.725 12.379 10.6583 12.2083 10.525 12.075L9.2 10.75H13.025C13.225 10.75 13.396 10.679 13.538 10.537C13.6793 10.3957 13.75 10.2167 13.75 10C13.75 9.78333 13.6793 9.604 13.538 9.462C13.396 9.32067 13.2167 9.25 13 9.25H9.2L10.55 7.9C10.6833 7.76667 10.746 7.59567 10.738 7.387C10.7293 7.179 10.6583 7.00833 10.525 6.875C10.375 6.74167 10.196 6.675 9.988 6.675C9.77933 6.675 9.60833 6.74167 9.475 6.875L7 9.375C6.81667 9.55833 6.725 9.76667 6.725 10C6.725 10.2333 6.81667 10.4417 7 10.625L9.5 13.15ZM10 19.5C8.68333 19.5 7.446 19.25 6.288 18.75C5.12933 18.25 4.125 17.575 3.275 16.725C2.425 15.875 1.75 14.8707 1.25 13.712C0.75 12.554 0.5 11.3167 0.5 10C0.5 8.68333 0.75 7.44567 1.25 6.287C1.75 5.129 2.425 4.125 3.275 3.275C4.125 2.425 5.12933 1.75 6.288 1.25C7.446 0.75 8.68333 0.5 10 0.5C11.3167 0.5 12.5543 0.75 13.713 1.25C14.871 1.75 15.875 2.425 16.725 3.275C17.575 4.125 18.25 5.129 18.75 6.287C19.25 7.44567 19.5 8.68333 19.5 10C19.5 11.3167 19.25 12.554 18.75 13.712C18.25 14.8707 17.575 15.875 16.725 16.725C15.875 17.575 14.871 18.25 13.713 18.75C12.5543 19.25 11.3167 19.5 10 19.5ZM10 18C12.2167 18 14.1043 17.221 15.663 15.663C17.221 14.1043 18 12.2167 18 10C18 7.78333 17.221 5.89567 15.663 4.337C14.1043 2.779 12.2167 2 10 2C7.78333 2 5.896 2.779 4.338 4.337C2.77933 5.89567 2 7.78333 2 10C2 12.2167 2.77933 14.1043 4.338 15.663C5.896 17.221 7.78333 18 10 18Z"
      fill="currentColor"
    />
  </svg>
)
