import classNames from 'classnames'
import styles from './index.module.scss'

export const CircleGreenIcon = (props) => {
  return (
    <svg className={classNames(styles.svg, props.className)} viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10" r="7" stroke="#66CBAD" strokeWidth="4" strokeLinejoin="round" />
    </svg>
  )
}
