import classNames from 'classnames'
import styles from './index.module.scss'

export const HelpIcon = (props) => (<svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" fill="none">
  <path
    d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20Z"
    fill="currentColor"
  />
  <path
    d="M12.0012 15.5C11.3012 15.5 10.7012 16 10.7012 16.7C10.7012 17.4 11.3012 18 12.0012 18C12.7012 18 13.2012 17.4 13.2012 16.7C13.3012 16 12.7012 15.5 12.0012 15.5Z"
    fill="currentColor"
  />
  <path
    d="M12.0003 6C10.7003 6 9.70033 6.7 9.00033 7.5C8.70033 8 8.90033 8.6 9.40033 8.8C9.80033 9 10.2003 8.8 10.5003 8.4C10.8003 8 11.3003 7.6 12.0003 7.6C13.6003 7.6 13.9003 9.1 13.4003 9.9C12.9003 10.7 11.9003 11.2 11.4003 12.1C11.2003 12.4 11.1003 12.8 11.1003 13.1C11.1003 13.6 11.5003 14.1 12.0003 14.1H12.1003C12.6003 14.1 12.9003 13.8 13.0003 13.3C13.0003 13 13.1003 12.8 13.2003 12.7C13.6003 12 14.3003 11.6 15.1003 10.5C15.8003 9.5 15.5003 8.1 15.1003 7.4C14.5003 6.7 13.5003 6 12.0003 6Z"
    fill="currentColor"
  />
</svg>)

