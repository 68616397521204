import classNames from 'classnames'
import styles from './index.module.scss'

export const CallIcon = (props) => (
  <svg
    className={classNames(styles.svg, props.className)}
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5 17.5C21.25 17.5 20.05 17.3 18.93 16.93C18.83 16.9 18.72 16.88 18.62 16.88C18.36 16.88 18.11 16.98 17.91 17.17L15.71 19.37C12.88 17.93 10.56 15.62 9.12 12.79L11.32 10.58C11.6 10.31 11.68 9.92 11.57 9.57C11.2 8.45 11 7.25 11 6C11 5.45 10.55 5 10 5H6.5C5.95 5 5.5 5.45 5.5 6C5.5 15.39 13.11 23 22.5 23C23.05 23 23.5 22.55 23.5 22V18.5C23.5 17.95 23.05 17.5 22.5 17.5ZM7.53 7H9.03C9.1 7.88 9.25 8.75 9.49 9.59L8.29 10.8C7.88 9.59 7.62 8.32 7.53 7ZM21.5 20.97C20.18 20.88 18.9 20.62 17.7 20.21L18.9 19.01C19.75 19.25 20.62 19.4 21.5 19.46V20.97ZM14.5 5V15L17.5 12H23.5V5H14.5ZM21.5 10H16.5V7H21.5V10Z"
      fill="currentColor"
    />
  </svg>
)
