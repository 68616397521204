import classNames from 'classnames'
import styles from './index.module.scss'

export const UploadPhotoIcon = (props) => (
  <svg className={classNames(styles.svg, props.className)} viewBox="0 0 40 40" fill="none">
    <rect width="40" height="40" rx="12" fill="#FAFAFA" />
    <path
      d="M26 23V26H14V23H12V26C12 27.1 12.9 28 14 28H26C27.1 28 28 27.1 28 26V23H26ZM15 17L16.41 18.41L19 15.83V24H21V15.83L23.59 18.41L25 17L20 12L15 17Z"
      fill="#0355BF"
    />
  </svg>
)
