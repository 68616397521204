/*
 * ------------------------------------------
 * EventEmitter 单例
 * @version  1.0
 * @author   zzj(hzzhangzhoujie@corp.netease.com)
 * ------------------------------------------
 */
import EventEmitter from 'events'

export default (function () {
  let eventEmitter
  eventEmitter = new EventEmitter()
  return eventEmitter
})()
