import classNames from 'classnames'
import styles from './index.module.scss'

export const WalletIcon = (props) => (
  <svg
    className={classNames(styles.svg, props.className)}
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.3"
      d="M23 12.5H8C6.95 12.5 6.0625 13.15 5.6875 14.0625L19.575 17.4625C19.9625 17.5625 20.375 17.4625 20.675 17.2125L25.0375 13.5625C24.575 12.925 23.8375 12.5 23 12.5Z"
      fill="#FFD032"
    />
    <path
      opacity="0.3"
      d="M23 7.5H8C6.625 7.5 5.5 8.625 5.5 10V10.6875C6.2375 10.2625 7.0875 10 8 10H23C23.9125 10 24.7625 10.2625 25.5 10.6875V10C25.5 8.625 24.375 7.5 23 7.5Z"
      fill="#0355BF"
    />
    <path
      d="M23 5H8C5.2375 5 3 7.2375 3 10V20C3 22.7625 5.2375 25 8 25H23C25.7625 25 28 22.7625 28 20V10C28 7.2375 25.7625 5 23 5ZM20.675 17.2125C20.375 17.4625 19.9625 17.5625 19.575 17.4625L5.6875 14.0625C6.0625 13.15 6.95 12.5 8 12.5H23C23.8375 12.5 24.575 12.925 25.0375 13.55L20.675 17.2125ZM25.5 10.6875C24.7625 10.2625 23.9125 10 23 10H8C7.0875 10 6.2375 10.2625 5.5 10.6875V10C5.5 8.625 6.625 7.5 8 7.5H23C24.375 7.5 25.5 8.625 25.5 10V10.6875Z"
      fill="#0355BF"
    />
  </svg>
)
