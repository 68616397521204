import classNames from 'classnames'
import styles from './index.module.scss'

export const ExpressIcon = (props) => (
  <svg
    className={classNames(styles.svg, props.className)}
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6663 3.66671H14.1663V0.333374H2.49967C1.58301 0.333374 0.833008 1.08337 0.833008 2.00004V11.1667H2.49967C2.49967 12.55 3.61634 13.6667 4.99967 13.6667C6.38301 13.6667 7.49967 12.55 7.49967 11.1667H12.4997C12.4997 12.55 13.6163 13.6667 14.9997 13.6667C16.383 13.6667 17.4997 12.55 17.4997 11.1667H19.1663V7.00004L16.6663 3.66671ZM16.2497 4.91671L17.883 7.00004H14.1663V4.91671H16.2497ZM4.99967 12C4.54134 12 4.16634 11.625 4.16634 11.1667C4.16634 10.7084 4.54134 10.3334 4.99967 10.3334C5.45801 10.3334 5.83301 10.7084 5.83301 11.1667C5.83301 11.625 5.45801 12 4.99967 12ZM6.84967 9.50004C6.39134 8.99171 5.74134 8.66671 4.99967 8.66671C4.25801 8.66671 3.60801 8.99171 3.14967 9.50004H2.49967V2.00004H12.4997V9.50004H6.84967ZM14.9997 12C14.5413 12 14.1663 11.625 14.1663 11.1667C14.1663 10.7084 14.5413 10.3334 14.9997 10.3334C15.458 10.3334 15.833 10.7084 15.833 11.1667C15.833 11.625 15.458 12 14.9997 12Z"
      fill="currentColor"
    />
  </svg>
)
