import classNames from 'classnames'
import styles from './index.module.scss'

export const ColorLoadingIcon = ({ className = '' }) => (
  <svg
    className={classNames(styles.svg, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    xmlSpace="preserve"
  >
    <g transform="translate(1 1)">
      <circle opacity="0.3" fill="none" stroke="#0355BF" strokeOpacity="0.5" strokeWidth="6" cx="18" cy="18" r="16" />
      <path fill="none" stroke="#0355BF" strokeLinecap="round" strokeWidth="6" d="M34,18c0-8.8-7.2-16-16-16">
        <animateTransform
          accumulate="none"
          additive="replace"
          attributeName="transform"
          calcMode="linear"
          dur="1s"
          fill="remove"
          from="0 18 18"
          repeatCount="indefinite"
          restart="always"
          to="360 18 18"
          type="rotate"
        ></animateTransform>
      </path>
    </g>
  </svg>
)
