import React, { useState, useRef, useEffect } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Slide from '@mui/material/Slide'
import Alert from '@mui/material/Alert'
import styles from './index.module.scss'

export const CustomSnackbar = (props) => {
  const duration = 3000
  const { message, severity, count, onClose } = props
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      onClose && onClose()
    }, 200)
  }

  useEffect(() => {
    setOpen(true)
  }, [duration, message, severity])

  return (
    <Snackbar
      className={styles.snackbar}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      style={{
        transform: `translate(-50%,${-120 * count}%)`,
      }}
      autoHideDuration={duration}
      open={open}
      onClose={handleClose}
      TransitionComponent={Slide}
      disableWindowBlurListener
    >
      <Alert variant="standard" onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}
