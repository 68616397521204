import classNames from 'classnames'
import styles from './index.module.scss'

export const LogoIcon = (props) => (
  <svg className={classNames(styles.svg, props.className)} viewBox="0 0 94 30" fill={props.color}>
    <path
      fill={props.color}
      d="M24.3857 21.5713L12.9977 28.1424L1.60963 21.5713V8.42868L12.9977 1.85763L24.3857 8.42868V21.5713ZM12.9977 0L0 7.50013V22.4999L12.9977 30L25.9953 22.4999V7.50013L12.9977 0Z"
    />
    <path
      fill={props.color}
      d="M16.0749 20.9644L9.92012 17.4131H8.31051V23.5664L9.92017 24.4955V19.2707L16.0749 22.822V24.4955L17.6845 23.5667V17.4131H16.0749V20.9644Z"
    />
    <path fill={props.color} d="M4.84084 17.4131H3.23121V20.6355L6.68914 22.6308V20.7732L4.84084 19.7067V17.4131Z" />
    <path fill={props.color} d="M21.1545 12.5869H22.7642V9.36452L19.3063 7.36919V9.22682L21.1545 10.2933V12.5869Z" />
    <path
      fill={props.color}
      d="M9.92033 7.36218L12.9977 5.58665L16.075 7.36218V8.35334H9.92033V7.36218ZM8.3107 12.5869H9.92033V9.96415L14.4654 12.5869H17.6846L13.1357 9.96207H17.6846V6.43362L12.9977 3.72903L8.3107 6.43337V12.5869Z"
    />
    <path
      fill={props.color}
      d="M4.84084 14.1956V10.2933L6.68914 9.22682V7.3692L3.23121 9.36452V15.8044H21.1545V19.7067L19.3062 20.7732V22.6308L22.7641 20.6355V14.1956H4.84084Z"
    />
    <path
      fill={props.color}
      d="M92.5483 14.9497C91.6647 14.0923 90.3738 13.5292 88.675 13.2596L85.016 12.6545C84.2757 12.537 83.7418 12.3564 83.4134 12.1126C83.0854 11.8687 82.9215 11.5281 82.9215 11.0908C82.9215 10.6205 83.1778 10.2463 83.6915 9.96894C84.204 9.69155 84.8979 9.55285 85.7731 9.55285C86.698 9.55285 87.6023 9.72119 88.4858 10.0567C89.3688 10.3934 90.2731 10.923 91.1981 11.6461L93.4945 8.44329C92.5354 7.66984 91.3872 7.05578 90.0498 6.60223C88.7125 6.14811 87.388 5.92105 86.0758 5.92105C83.8213 5.92105 82.0514 6.4132 80.7644 7.39637C79.4774 8.3801 78.8339 9.72119 78.8339 11.4185C78.8339 12.7138 79.2205 13.7814 79.9944 14.6219C80.7683 15.4625 81.8701 16.0089 83.3003 16.2611L87.186 16.9417C88.0606 17.0933 88.6873 17.3036 89.0661 17.5726C89.4444 17.8416 89.6335 18.2196 89.6335 18.7073C89.6335 19.2453 89.3521 19.6653 88.7886 19.9684C88.2245 20.271 87.4467 20.422 86.4546 20.422C85.4451 20.422 84.4771 20.229 83.5527 19.842C82.6272 19.4556 81.6345 18.8331 80.5747 17.9758L78.1523 21.1283C79.2457 22.0533 80.4952 22.7764 81.8997 23.2971C83.3042 23.8183 84.7132 24.0789 86.1261 24.0789C88.5149 24.0789 90.4035 23.5577 91.7912 22.5153C93.1789 21.4728 93.8728 20.0607 93.8728 18.2784C93.8728 16.9166 93.4313 15.807 92.5483 14.9497Z"
    />
    <path
      fill={props.color}
      d="M53.2912 14.773C53.8967 13.8988 54.1995 12.9067 54.1995 11.7972C54.1995 10.9734 54.0438 10.2173 53.7328 9.52714C53.4213 8.83814 52.9923 8.24588 52.4458 7.74926C51.899 7.25376 51.243 6.86676 50.4776 6.58937C49.712 6.31198 48.8754 6.17329 47.9668 6.17329H37.916V23.8267H42.2562V9.88058H47.5632C48.236 9.88058 48.7783 10.0657 49.1908 10.4354C49.6028 10.8056 49.8091 11.2843 49.8091 11.8727C49.8091 12.4615 49.6028 12.9408 49.1908 13.3105C48.7783 13.6802 48.236 13.8653 47.5632 13.8653H44.7504L49.9099 23.8267H54.7547L50.8941 16.7656C51.8864 16.3115 52.6855 15.6476 53.2912 14.773Z"
    />
    <path
      fill={props.color}
      d="M71.9233 18.7862L63.5292 14.7769V19.3051L72.9965 23.8267H76.0109V6.17329H71.9233V18.7862Z"
    />
    <path
      fill={props.color}
      d="M69.5797 10.6949L60.1123 6.17329H57.098V23.8267H61.1858V11.2138L69.5797 15.2232V10.6949Z"
    />
  </svg>
)
