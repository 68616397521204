import * as Sentry from '@sentry/react'
import axios from 'axios'
import globalStore from '@/store/globalStore'
import { snackbarShowMessage } from '@/components/Snackbar'
import { GyAxios, GyResponse } from '@/interface/axios'
import { isServer } from '@/utils/isServer'
import services from './account'
import { logout } from '@/utils/logout'

// 400 errors have been handled in this array
const not_toast_array = [
  '/user/change_address/',
  '/order/prepare/',
  '/account/wallet/email/',
  '/user/update_to_web3/',
  '^/application/([^/]*-[^/]*)/$',
]

const not_toast_array2 = [
  '/goods/redeem_ability/',
  '/application/verify_sumsub/',
  '/account/check_ip/',
  '/payment/web3/polling/',
  '/separate_activity/dr_inauguration_ceremony/',
  '/website/logs/',
]

const not_login_array = ['/goods/redeem_ability/']

const handleError = (status, url) => {
  const isMatch = not_toast_array.some((item) => {
    const regExp = new RegExp(item)
    return regExp.test(url)
  })

  const isNotToast = not_toast_array2.some((item) => {
    const regExp = new RegExp(item)
    return regExp.test(url)
  })

  if (isNotToast) {
    return
  }

  //  400 errors have not been handled
  if (status.code === 400 && !isMatch) {
    if (status.message) {
      try {
        const keys = Object.keys(status.message)
        for (const key of keys) {
          snackbarShowMessage(key + ': ' + status.message[key], 'error')
        }
      } catch (error) {
        console.error(error.message)
      }
    }
  }

  if (status.code === 401) {
    logout(401)
  }

  if (
    (status.code === 403 || status.code === 404 || status.code === 405 || status.code === 460 || status.code === 500) &&
    status.message
  ) {
    snackbarShowMessage(status.message, 'error')
  }
}

const configuredAxios: GyAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_DOMAIN + '/api/v2/portal',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Accept-Language': isServer
      ? 'en'
      : window.localStorage.getItem('rns-lng') === 'cn'
      ? 'zh-hans'
      : window.localStorage.getItem('rns-lng') === 'jp'
      ? 'ja'
      : window.localStorage.getItem('rns-lng') || 'en',
  },
  timeout: 100000,
})

configuredAxios.interceptors.request.use(function (config) {
  let token = globalStore.token || window.localStorage.getItem('rns-token')
  if (token && !not_login_array.includes(config.url)) {
    config.headers.Authorization = `Token ${token}`
  }
  const newUrl = appendURL(config.url, {
    __timestamp: new Date().getTime(),
  })
  config.url = newUrl
  return config
})

configuredAxios.interceptors.response.use(
  function (response) {
    // @ts-ignore
    const data: GyResponse<any, any> = response.data
    const { status } = data
    if (status && (status.code === 201 || status.code === 204)) {
      data.status.code == 200
    }
    return data
  },
  function (error) {
    // http code: error.response.status
    // server code: error.response.data.status.code
    const response = error.response || {}
    let status: any = {}
    // server error
    if (typeof response.data === 'object') {
      status = response.data.status || {}
      // network error
    } else if (error.message) {
      status = {
        code: 460,
        message: error.message === 'Network Error' || error.message === 'Request aborted' ? '' : error.message,
      }
    }

    error.message = error.message + '|' + error.code + '|' + (response.status || 0)
    if (!(error.code === 'ERR_NETWORK' || error.code === 'ECONNABORTED' || error.code === 'ERR_BAD_REQUEST')) {
      Sentry.captureException(error)
    }
    handleError(status, (response.config && response.config.url) || '')
    return {
      status,
    }
  },
)

const appendURL = (_baseUrl, _optional) => {
  const baseURL = _baseUrl || ''
  if (!_optional) {
    return baseURL
  }

  const urlArray = extractUrl(baseURL) || []
  const protocol = urlArray[1] || ''
  const segment = urlArray[2] || ''
  const host = urlArray[3] || ''
  const pathname = urlArray[4] || ''
  const search = urlArray[5] || ''
  const anchor = urlArray[6] || ''

  let blank_character = '?'
  if (search) {
    blank_character = '&'
  }

  const appendSearch = blank_character + convertToQueryString(_optional)
  const result = protocol + segment + host + pathname + search + appendSearch + anchor
  return result.replace(/&+/gm, '&')
}

const extractUrl = (url) => {
  url = url || ''
  const re = /(\w+(?=\:))?([:]?\/{2,})?([^\/|^\?|^#]+)?([^#|^\?]+)?(\?[^#]+)?(#[^\?]+)?/i
  const arr = url.match(re)
  return arr
}

const convertToQueryString = (param, key = '', encode = true) => {
  if (typeof param === 'undefined') return ''
  let str = '',
    value = ''
  const type = typeof param
  if (type === 'number' || type === 'string' || type === 'boolean') {
    value = encode ? encodeURIComponent(param) : param
    str += `&${key}=${value}`
  } else {
    for (const i in param) {
      const prefix = !key ? i : `${key}[${i}]`
      str += '&' + convertToQueryString(param[i], prefix, encode)
    }
  }
  return str.substring(1)
}

export default configuredAxios
