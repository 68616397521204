import classNames from 'classnames'
import styles from './index.module.scss'

export const ChatIcon = (props) => (
  <svg
    className={classNames(styles.svg, props.className)}
    viewBox="0 0 26 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.25 12.275C24.25 5.4125 18.925 0.75 13 0.75C7.1375 0.75 1.75 5.3125 1.75 12.35C1 12.775 0.5 13.575 0.5 14.5V17C0.5 18.375 1.625 19.5 3 19.5H4.25V11.875C4.25 7.0375 8.1625 3.125 13 3.125C17.8375 3.125 21.75 7.0375 21.75 11.875V20.75H11.75V23.25H21.75C23.125 23.25 24.25 22.125 24.25 20.75V19.225C24.9875 18.8375 25.5 18.075 25.5 17.175V14.3C25.5 13.425 24.9875 12.6625 24.25 12.275Z"
      fill="#717C7A"
    />
    <path
      d="M9.25 14.5C9.94036 14.5 10.5 13.9404 10.5 13.25C10.5 12.5596 9.94036 12 9.25 12C8.55964 12 8 12.5596 8 13.25C8 13.9404 8.55964 14.5 9.25 14.5Z"
      fill="#717C7A"
    />
    <path
      d="M16.75 14.5C17.4404 14.5 18 13.9404 18 13.25C18 12.5596 17.4404 12 16.75 12C16.0596 12 15.5 12.5596 15.5 13.25C15.5 13.9404 16.0596 14.5 16.75 14.5Z"
      fill="#717C7A"
    />
    <path
      d="M20.5001 10.7875C19.9001 7.225 16.8001 4.5 13.0626 4.5C9.27508 4.5 5.20008 7.6375 5.52508 12.5625C8.61258 11.3 10.9376 8.55 11.6001 5.2C13.2376 8.4875 16.6001 10.75 20.5001 10.7875Z"
      fill="#717C7A"
    />
  </svg>
)
