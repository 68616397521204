import classNames from 'classnames'
import styles from './index.module.scss'

export const Wallet2Icon = (props) => (
  <svg
    className={classNames(styles.svg, props.className)}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99996 22.75C5.87218 22.75 4.90968 22.3514 4.11246 21.5542C3.31524 20.7569 2.91663 19.7944 2.91663 18.6667V9.33333C2.91663 8.20556 3.31524 7.24306 4.11246 6.44583C4.90968 5.64861 5.87218 5.25 6.99996 5.25H21C22.1277 5.25 23.0902 5.64861 23.8875 6.44583C24.6847 7.24306 25.0833 8.20556 25.0833 9.33333V18.6667C25.0833 19.7944 24.6847 20.7569 23.8875 21.5542C23.0902 22.3514 22.1277 22.75 21 22.75H6.99996ZM6.99996 9.625H21C21.4472 9.625 21.8652 9.688 22.2541 9.814C22.643 9.94078 23.0027 10.1306 23.3333 10.3833V9.33333C23.3333 8.69167 23.105 8.14256 22.6485 7.686C22.1911 7.22867 21.6416 7 21 7H6.99996C6.35829 7 5.80918 7.22867 5.35263 7.686C4.89529 8.14256 4.66663 8.69167 4.66663 9.33333V10.3833C4.99718 10.1306 5.3569 9.94078 5.74579 9.814C6.13468 9.688 6.55274 9.625 6.99996 9.625ZM4.78329 13.125L17.8791 16.3042C18.0347 16.3431 18.1953 16.3431 18.361 16.3042C18.5258 16.2653 18.6763 16.1972 18.8125 16.1L23.0125 12.5708C22.818 12.2014 22.5411 11.9097 22.1818 11.6958C21.8217 11.4819 21.4277 11.375 21 11.375H6.99996C6.45551 11.375 5.97913 11.5352 5.57079 11.8557C5.16246 12.1769 4.89996 12.6 4.78329 13.125Z"
      fill="currentColor"
    />
  </svg>
)
