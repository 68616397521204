import React from 'react'

export const LogoDiscord = function ({ color }) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40">
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM23.5455 10.625C23.5455 10.625 26.7014 11.1554 28.6979 12.1552C28.6979 12.1552 33.2026 17.4287 32.4054 25.7331C32.4054 25.7331 29.5068 27.817 26.0949 28.7487C25.5697 28.1143 25.1213 27.4239 24.7585 26.6912C24.7683 26.6912 26.4925 26.014 26.8248 25.7034C26.8438 25.7217 26.3411 25.2921 26.3411 25.2921C26.3411 25.2921 23.5442 26.6831 19.9999 26.6831C16.4555 26.6831 13.66 25.2933 13.66 25.2933C13.66 25.2933 13.1573 25.723 13.1762 25.7047C13.5085 26.0153 15.2327 26.6925 15.2425 26.6925C14.8797 27.4252 14.4313 28.1155 13.9061 28.75C10.4943 27.817 7.59427 25.7318 7.59427 25.7318C6.79844 17.4287 11.3031 12.1552 11.3031 12.1552C13.2996 11.1554 16.4555 10.625 16.4555 10.625L17.1286 11.9234C17.1286 11.9234 18.8012 11.7041 20.0012 11.7041C21.2011 11.7041 22.8737 11.9234 22.8737 11.9234L23.5455 10.625ZM13.5646 20.6038C13.5646 21.9198 14.5818 22.9908 15.8366 22.9908L15.8385 22.9889C17.0933 22.9889 18.1085 21.9198 18.1085 20.6038C18.1085 19.2878 17.0914 18.2168 15.8366 18.2168C14.5818 18.2168 13.5646 19.2878 13.5646 20.6038ZM21.8899 20.6038C21.8899 21.9198 22.907 22.9908 24.1618 22.9908L24.1638 22.9889C25.4199 22.9889 26.4338 21.9198 26.4338 20.6038C26.4338 19.2878 25.4166 18.2168 24.1618 18.2168C22.907 18.2168 21.8899 19.2878 21.8899 20.6038Z"
        fill={color}
      />
    </svg>
  )
}