import React, { useEffect, useState } from 'react'
import { Trans, t } from '@lingui/macro'
import Button from '../Button'
import { remove } from 'lodash/array'

import styles from './index.module.scss'
import CustomDialog from '../CustomDialog'
import { Switch } from '@mui/material'

function AgreeCookiePolicy({ display, handleClose }) {
  const cookieList = [
    {
      code: 'C0001',
      name: <Trans>Strictly Necessary</Trans>,
      content: (
        <Trans>
          These cookies are necessary for our website to function properly and cannot be switched off in our systems.
          They are usually only set in response to actions made by you which amount to a request for services, such as
          setting your privacy preferences, logging in or filling in forms or where they’re essential to provide you
          with a service you have requested. You cannot opt-out of these cookies. You can set your browser to block or
          alert you about these cookies, but if you do, some parts of the site will not then work. These cookies do not
          store any personally identifiable information.
        </Trans>
      ),
    },
    {
      code: 'C0002',
      name: <Trans>Performance Cookies</Trans>,
      content: (
        <Trans>
          These cookies allow us to count visits and traffic sources so we can measure and improve the performance of
          our site. They help us to know which pages are the most and least popular and see how visitors move around the
          site, which helps us optimize your experience. All information these cookies collect is aggregated and
          therefore anonymous. If you do not allow these cookies we will not be able to use your data in this way.{' '}
          <a href="/app/cookiePolicy" target="_blank">
            Cookie details
          </a>
        </Trans>
      ),
    },
    {
      code: 'C0003',
      name: <Trans>Functional Cookies</Trans>,
      content: (
        <Trans>
          These cookies enable the website to provide enhanced functionality and personalization. They may be set by us
          or by third party providers whose services we have added to our pages. If you do not allow these cookies then
          some or all of these services may not function properly.{' '}
          <a href="/app/cookiePolicy" target="_blank">
            Cookie details
          </a>
        </Trans>
      ),
    },
    {
      code: 'C0004',
      name: <Trans>Targeting Cookies</Trans>,
      content: (
        <Trans>
          These cookies are used to make advertising messages more relevant to you and may be set through our site by us
          or by our advertising partners. They may be used to build a profile of your interests and show you relevant
          advertising on our site or on other sites. They do not store directly personal information, but are based on
          uniquely identifying your browser and internet device.{' '}
          <a href="/app/cookiePolicy" target="_blank">
            Cookie details
          </a>
        </Trans>
      ),
    },
  ]

  let acceptList = []
  try {
    acceptList = JSON.parse(localStorage.getItem('rns-accept-list') || '[]')
  } catch (error) {}
  const [expandObject, setExpandObject] = useState({})
  const [checkedList, setCheckedList] = useState<any>(acceptList)
  const [open, setOpen] = useState(false)

  const handleExpandItem = (index) => {
    setExpandObject({
      ...expandObject,
      [index]: !expandObject[index],
    })
  }

  const handleCheckedItem = (code) => {
    if (checkedList.includes(code)) {
      remove(checkedList, (item) => {
        return item === code
      })
    } else {
      checkedList.push(code)
    }
    setCheckedList(checkedList.concat())
  }

  const handleAccept = (checkedList) => {
    setCheckedList(checkedList.concat())
    localStorage.setItem('rns-accept-list', JSON.stringify(checkedList))
    handleClose()
  }

  return (
    <>
      <div className={styles.cookiePolicy} style={{ display: display ? 'block' : 'none' }}>
        <div className={styles.title}>
          <Trans>Welcome to RNS ID!</Trans>
        </div>
        <div className={styles.content}>
          <Trans>
            We utilize cookies to ensure that our website and services function effectively and to provide you with the
            most relevant, enhanced, and personalized web experience possible. Additionally, these will remember your
            preferences and subsequent visits.
            <br /> <br />
            By selecting “Accept all cookies”, you consent to the use of ALL cookies in accordance with our{' '}
            <a href="/app/cookiePolicy" target="_blank">
              Cookie Policy
            </a>
            .
          </Trans>
        </div>
        <div className={styles.btnGroup}>
          <Button
            onClick={() => {
              handleAccept([cookieList[0].code])
            }}
            size="small"
            ghost
            text={(<Trans>Necessary cookies only</Trans>) as any}
          />
          <Button
            onClick={() => {
              handleAccept([cookieList[0].code, cookieList[1].code, cookieList[2].code, cookieList[3].code])
            }}
            size="small"
            text={(<Trans>Accept all cookies</Trans>) as any}
          />
        </div>
        <Button
          ghost
          size="small"
          onClick={() => {
            setOpen(true)
          }}
          className={styles.customizeBtn}
          text={(<Trans>Customize cookie settings</Trans>) as any}
        />
      </div>
      <CustomDialog
        btnText1={(<Trans>Accept all cookies</Trans>) as any}
        btnText2={(<Trans>Confirm my choices</Trans>) as any}
        onClickBtn1={() => {
          handleAccept([cookieList[0].code, cookieList[1].code, cookieList[2].code, cookieList[3].code])
        }}
        onClickBtn2={() => {
          handleAccept(checkedList)
        }}
        open={open}
        setOpen={setOpen}
        title={<Trans>Cookie Settings</Trans>}
      >
        <div className={styles.cookieSettings}>
          <div className={styles.desc}>
            <Trans>
              When you visit any of our websites, it may store or retrieve information on your browser, mostly in the
              form of cookies. This information might be about you, your preferences or your device and is mostly used
              to make the site work as you expect it to. The information does not usually directly identify you, but it
              can give you a more personalized web experience. Because we respect your right to privacy, you can choose
              not to allow some types of cookies. Click on the different category headings to find out more and manage
              your preferences. Please note, blocking some types of cookies may impact your experience of the site and
              the services we are able to offer.
            </Trans>
          </div>
          <div className={styles.cookieList}>
            {cookieList.map((item, index) => {
              return (
                <div key={index} className={styles.cookieItem}>
                  <div
                    className={styles.left}
                    onClick={() => {
                      handleExpandItem(index)
                    }}
                  >
                    {expandObject[index] ? (
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.04175 10.8333C4.80564 10.8333 4.60078 10.7465 4.42716 10.5729C4.25355 10.3993 4.16675 10.1945 4.16675 9.95834C4.16675 9.72223 4.25355 9.51737 4.42716 9.34376C4.60078 9.17015 4.80564 9.08334 5.04175 9.08334H14.9584C15.1945 9.08334 15.3994 9.17015 15.573 9.34376C15.7466 9.51737 15.8334 9.72223 15.8334 9.95834C15.8334 10.1945 15.7466 10.3993 15.573 10.5729C15.3994 10.7465 15.1945 10.8333 14.9584 10.8333H5.04175Z"
                          fill="#4F4F4F"
                        />
                      </svg>
                    ) : (
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.99996 15.8333C9.76385 15.8333 9.55899 15.7465 9.38538 15.5729C9.21176 15.3993 9.12496 15.1966 9.12496 14.9648V10.875H5.03511C4.80334 10.875 4.60065 10.7882 4.42704 10.6146C4.25343 10.441 4.16663 10.2361 4.16663 9.99999C4.16663 9.76388 4.25343 9.55902 4.42704 9.38541C4.60065 9.2118 4.80334 9.12499 5.03511 9.12499H9.12496V5.03514C9.12496 4.80337 9.21176 4.60068 9.38538 4.42707C9.55899 4.25346 9.76385 4.16666 9.99996 4.16666C10.2361 4.16666 10.4409 4.25346 10.6145 4.42707C10.7882 4.60068 10.875 4.80337 10.875 5.03514V9.12499H14.9648C15.1966 9.12499 15.3993 9.2118 15.5729 9.38541C15.7465 9.55902 15.8333 9.76388 15.8333 9.99999C15.8333 10.2361 15.7465 10.441 15.5729 10.6146C15.3993 10.7882 15.1966 10.875 14.9648 10.875H10.875V14.9648C10.875 15.1966 10.7882 15.3993 10.6145 15.5729C10.4409 15.7465 10.2361 15.8333 9.99996 15.8333Z"
                          fill="#4F4F4F"
                        />
                      </svg>
                    )}
                  </div>
                  <div className={styles.right}>
                    <div
                      className={styles.line1}
                      onClick={() => {
                        handleExpandItem(index)
                      }}
                    >
                      <div>{item.name}</div>
                      <Switch
                        disabled={index === 0}
                        checked={checkedList.includes(item.code) || index === 0}
                        onClick={(event) => {
                          event.stopPropagation()
                          handleCheckedItem(item.code)
                        }}
                      />
                    </div>
                    {expandObject[index] && (
                      <div className={styles.line2} dangerouslySetInnerHTML={{ __html: item.content as any }}></div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </CustomDialog>
    </>
  )
}

export default AgreeCookiePolicy
