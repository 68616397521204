import styles from './index.module.scss'

export const BlackPointIcon = () => (
  <svg className={styles.icon} width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.66634 0.333008H2.33301C1.22844 0.333008 0.333008 1.22844 0.333008 2.33301V3.66634C0.333008 4.77091 1.22844 5.66634 2.33301 5.66634H3.66634C4.77091 5.66634 5.66634 4.77091 5.66634 3.66634V2.33301C5.66634 1.22844 4.77091 0.333008 3.66634 0.333008Z"
      fill="#2D3540"
    />
  </svg>
)
