
import React from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

export function CircleGrayIcon(props) {
    return (
        <svg className={classNames(styles.svg, props.className)} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="7" stroke="#F1F2F2" strokeWidth="4" strokeLinejoin="round" />
        </svg>
    )
}