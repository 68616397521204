import Image from 'next/image'
import classnames from 'classnames'

import Button from '@/components/Button'
import { MuiDialog2 } from '@/components/MuiDialog2'

import styles from './index.module.scss'

interface Props {
  className?: string
  title?: any
  desc?: any
  btnText1?: string
  btnText2?: string
  onClickBtn1?: () => any
  onClickBtn2?: () => any
  status?: number
  open: boolean
  setOpen: (open: boolean) => any
  children?: any
  onClose?: () => any
  isDirectlyCloseByOk?: boolean
  buttonIsLoading?: boolean
  btnStyle2?: any
}

const CustomDialog = ({
  title,
  desc = '',
  btnText1,
  btnText2,
  className,
  onClickBtn1 = () => {},
  onClickBtn2 = () => {},
  status = 0,
  open = false,
  setOpen,
  children,
  onClose,
  isDirectlyCloseByOk = true,
  buttonIsLoading = false,
  btnStyle2 = null,
}: Props) => {
  const handleCancel = () => {
    setOpen(false)
    onClickBtn1()
  }

  const handleOk = () => {
    if (isDirectlyCloseByOk) {
      setOpen(false)
    }
    onClickBtn2()
  }

  return (
    <MuiDialog2
      open={open}
      onClose={() => {
        setOpen(false)
        onClose && onClose()
      }}
    >
      <div
        className={classnames({
          [styles.box]: true,
          [className]: className,
          [styles.minWidthBox]: !children,
        })}
      >
        {children ? (
          <>
            {title && <div className={styles.title}>{title}</div>}
            {desc &&
              (typeof desc === 'object' ? (
                <p className={styles.desc}>{desc}</p>
              ) : (
                <p className={styles.desc} dangerouslySetInnerHTML={{ __html: desc }}></p>
              ))}
            {children}
          </>
        ) : (
          <>
            <div className={styles.text}>
              <p
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: title }}
                style={!!desc ? null : { marginBottom: 0 }}
              ></p>
              {desc &&
                (typeof desc === 'object' ? (
                  <p className={styles.desc}>{desc}</p>
                ) : (
                  <p className={styles.desc} dangerouslySetInnerHTML={{ __html: desc }}></p>
                ))}
            </div>
          </>
        )}
        {(btnText1 || btnText2) && (
          <div className={styles.btnBox}>
            {btnText1 && <Button ghost text={btnText1} className={styles.btn1} onClick={handleCancel} />}
            {btnText2 && (
              <Button
                style={btnStyle2}
                isLoading={buttonIsLoading}
                text={btnText2}
                className={styles.btn2}
                onClick={handleOk}
              />
            )}
          </div>
        )}
      </div>
    </MuiDialog2>
  )
}

export default CustomDialog
