import axios from './configuredAxios'
import {
  RegisterReq,
  RegisterResp,
  SendEmailReq,
  LoginReq,
  LoginResp,
  NonceResp,
  WalletReq,
  WalletResp,
  Profile,
  ResetPassword,
  VerifyCode,
} from '@/interface/account'

const services = {
  /**
   * @description register
   */
  register: (data: RegisterReq) => axios.post<RegisterResp>('/account/register/', data),

  /**
   * @description sendRegisterEmail
   */
  sendRegisterEmail: (data: SendEmailReq) => axios.post('/account/register/email/', data),

  /**
   * @description login
   */
  login: (data: LoginReq) => axios.post<LoginResp>('/account/login/', data),

  /**
   * @description
   */
  loginTotp: (data) => axios.post<LoginResp>('/account/login_validate_totp/', data),

  /**
   * @description sendLoginEmail
   */
  sendLoginEmail: (data) => axios.post<LoginResp>('/account/login/send_login_email/', data),

  /**
   * @description Profile
   */
  profile: () => axios.get<Profile>('/account/profile/'),

  /**
   * @description getNonce
   */
  getNonce: (data: string) => axios.get<NonceResp>('/account/wallet/nonce/?wallet_ext=' + data),

  /**
   * @description login&register wallet
   */
  walletContinue: (data: WalletReq) => axios.post<WalletResp>('/account/wallet/continue/', data),

  /**
   * @description walletBindEmail
   */
  // walletBindEmail: (data: SendEmailReq) => axios.post('/account/wallet/email/', data),

  /**
   * @description sendResetEmail
   */
  sendResetEmail: (data: SendEmailReq) => axios.post('/account/reset/email/', data),

  /**
   * @description reset
   */
  reset: (data: ResetPassword) => axios.post('/account/reset/', data),

  /**
   * @description verifyCode
   */
  verifyCode: (data: VerifyCode) => axios.get('/account/verify_code/?email=' + data.email + '&code=' + data.code),

  /**
   * @description logout
   */
  logout: () => axios.get('/account/logout/'),

  /**
   * @description isExistAccount
   */
  isExistAccount: (data: string) => axios.get('/account/wallet/verify/?wallet_ext=' + data),

  /**
   * @description bindEmail_password
   */
  // bindEmail_password: (data) => axios.post('/account/bind/email_password/', data),

  /**
   * @description consent_clause
   */
  consentClause: () => axios.post('/account/consent_clause/'),

  /**
   * @description getTokenList
   */
  getTokenList: () => axios.get('/account/token/'),

  /**
   * @description createToken
   */
  createToken: (data) => axios.post('/account/token/', data),

  /**
   * @description kycVerifyByPwd
   */
  kycVerifyByPwd: (data) => axios.post('/account/kyc_verify/', data),

  /**
   * @description kycSignVerify
   */
  kycSignVerify: (data) => axios.get('/account/kyc_verify/', { params: data }),

  /**
   * @description deleteKycToken
   */
  deleteKycToken: (id) => axios.delete('/account/token/' + id + '/'),

  /**
   * @description socialAccount
   */
  socialAccount: () => axios.get('/account/social/social_account/'),

  /**
   * @description socialDisconnect
   */
  socialDisconnect: (provider, socialId) => axios.post('/account/social/disconnect/' + provider + '/' + socialId + '/'),
}

export default services
