import styles from './index.module.scss'

export const BenefitsIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1667 20.8833L18.9876 17.0042C19.182 16.8292 19.3329 16.6102 19.4402 16.3473C19.5468 16.0852 19.6001 15.8083 19.6001 15.5167C19.6001 14.9333 19.3912 14.4324 18.9736 14.014C18.5551 13.5963 18.064 13.3875 17.5001 13.3875C17.1112 13.3875 16.7371 13.4894 16.3777 13.6932C16.0176 13.8977 15.614 14.2528 15.1667 14.7583C14.6806 14.2333 14.2626 13.8736 13.9126 13.6792C13.5626 13.4847 13.2029 13.3875 12.8334 13.3875C12.2695 13.3875 11.7787 13.5963 11.3611 14.014C10.9426 14.4324 10.7334 14.9333 10.7334 15.5167C10.7334 15.8083 10.7871 16.0852 10.8944 16.3473C11.001 16.6102 11.1515 16.8292 11.3459 17.0042L15.1667 20.8833ZM16.2167 25.375C15.8862 25.7056 15.4732 25.8708 14.9777 25.8708C14.4815 25.8708 14.0681 25.7056 13.7376 25.375L3.47091 15.1083C3.29591 14.9333 3.1598 14.7389 3.06258 14.525C2.96536 14.3111 2.91675 14.0778 2.91675 13.825V5.48333C2.91675 4.99722 3.08708 4.58422 3.42775 4.24433C3.76764 3.90366 4.18064 3.73333 4.66675 3.73333H13.0084C13.2417 3.73333 13.4704 3.78194 13.6944 3.87916C13.9176 3.97639 14.107 4.10277 14.2626 4.25833L24.5292 14.525C24.8792 14.875 25.0542 15.2977 25.0542 15.7932C25.0542 16.2894 24.889 16.7028 24.5584 17.0333L16.2167 25.375ZM14.9626 24.15L23.3042 15.8083L13.0084 5.48333H4.66675V13.825L14.9626 24.15ZM7.58341 9.85833C7.99175 9.85833 8.33708 9.71716 8.61941 9.43483C8.90097 9.15327 9.04175 8.80833 9.04175 8.4C9.04175 7.99166 8.90097 7.64633 8.61941 7.364C8.33708 7.08244 7.99175 6.94166 7.58341 6.94166C7.17508 6.94166 6.83014 7.08244 6.54858 7.364C6.26625 7.64633 6.12508 7.99166 6.12508 8.4C6.12508 8.80833 6.26625 9.15327 6.54858 9.43483C6.83014 9.71716 7.17508 9.85833 7.58341 9.85833Z"
      fill="currentColor"
    />
  </svg>
)
