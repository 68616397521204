import classNames from 'classnames'
import styles from './index.module.scss'

export const BurgerCrossIcon = (props) => (
  <svg className={classNames(styles.svg, props.className)} viewBox="0 0 40 40" fill="none">
    <rect width="40" height="40" rx="12" fill="#FAFAFA" />
    <path
      d="M26.3 13.71C25.91 13.32 25.28 13.32 24.89 13.71L20 18.59L15.11 13.7C14.72 13.31 14.09 13.31 13.7 13.7C13.31 14.09 13.31 14.72 13.7 15.11L18.59 20L13.7 24.89C13.31 25.28 13.31 25.91 13.7 26.3C14.09 26.69 14.72 26.69 15.11 26.3L20 21.41L24.89 26.3C25.28 26.69 25.91 26.69 26.3 26.3C26.69 25.91 26.69 25.28 26.3 24.89L21.41 20L26.3 15.11C26.68 14.73 26.68 14.09 26.3 13.71Z"
      fill="#0355BF"
    />
  </svg>
)
