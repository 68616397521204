import services from '@/services/account'
import { disconnect } from '@wagmi/core'
import { config } from './getDefaultConfig'

export const logout = async (code?) => {
  const clearStorage = async () => {
    // @ts-ignore
    if (window.ReactNativeWebView) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(
        encodeURIComponent(
          JSON.stringify({
            method: 'logout',
            params: encodeURIComponent(JSON.stringify([])),
            callback: true,
            inject: {},
          }),
        ),
      )
    }
    try {
      disconnect(config)
    } catch (error) {}
    window.localStorage.removeItem('rns-token')
    window.localStorage.removeItem('rns-kyc-sign')
    window.location.href = '/app/login'
  }

  try {
    if (code === 401) {
      clearStorage()
      return
    }
    const results = await services.logout()
    if (results?.status?.code === 200) {
      clearStorage()
    }
  } catch (error) {}
}
