import classNames from 'classnames'
import styles from './index.module.scss'

export const LogoMiniIcon = (props) => (
  <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.1938 23.0094L13.9614 30.0185L1.72898 23.0094V8.9906L13.9614 1.98147L26.1938 8.9906V23.0094ZM13.9614 0L0 8.00014V23.9999L13.9614 32L27.9228 23.9999V8.00014L13.9614 0Z"
      fill="#0355BF"
    />
    <path
      d="M17.2668 22.362L10.6556 18.574H8.9267V25.1375L10.6557 26.1285V20.5554L17.2668 24.3435V26.1285L18.9958 25.1378V18.574H17.2668V22.362Z"
      fill="#0355BF"
    />
    <path d="M5.19977 18.574H3.47079V22.0112L7.18511 24.1395V22.1581L5.19977 21.0205V18.574Z" fill="#0355BF" />
    <path d="M22.7231 13.426H24.452V9.98882L20.7377 7.86047V9.84194L22.7231 10.9796V13.426Z" fill="#0355BF" />
    <path
      d="M10.6559 7.85299L13.9614 5.95909L17.2669 7.85299V8.91023H10.6559V7.85299ZM8.92691 13.426H10.6559V10.6284L15.5379 13.426H18.9959L14.1097 10.6262H18.9959V6.86253L13.9614 3.97763L8.92691 6.86226V13.426Z"
      fill="#0355BF"
    />
    <path
      d="M5.19977 15.142V10.9796L7.18511 9.84194V7.86048L3.47079 9.98882V16.858H22.723V21.0205L20.7377 22.1581V24.1395L24.452 22.0112V15.142H5.19977Z"
      fill="#0355BF"
    />
  </svg>
)
