import React from 'react'
import classnames from 'classnames'
import styles from './index.module.scss'
import { LoadingIcon } from '../icons'

function ButtonComponent({
  text = '',
  ghost = false,
  size = '',
  redGhost = false,
  type = 'button',
  isEnabled = true,
  //视觉上禁用实际可点击
  styleIsEnabled = true,
  className = '',
  onClick = (event?) => {},
  isLoading = false,
  children = null,
  style = null,
}) {
  return (
    <button
      className={classnames(className, styles.button, {
        [styles.enabled]: isEnabled && styleIsEnabled,
        [styles.ghost]: isEnabled && ghost,
        [styles.redGhost]: isEnabled && redGhost,
        [styles.small]: size === 'small',
        [styles.middle]: size === 'middle',
        [styles.linkBtn]: type === 'link',
        [styles.iconBtn]: type === 'icon',
      })}
      type={type as any}
      disabled={!isEnabled}
      onClick={onClick}
      style={style}
    >
      {children ? children : text}
      {isLoading && <LoadingIcon color={isEnabled ? (ghost ? '#232323' : redGhost ? '#d92d2d' : '#fff') : '#d0d3d3'} />}
    </button>
  )
}

export default ButtonComponent
