import { Dialog } from '@mui/material'
import classnames from 'classnames'
import styles from './index.module.scss'

// custom mui modal style
export const MuiDialog2 = (props) => {
  const { children, className = '', ...rest } = props
  return (
    <Dialog {...rest} className={classnames(styles.dialog, className)}>
      <div className={styles.innerBox}>{props.children}</div>
    </Dialog>
  )
}
