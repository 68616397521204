import classNames from 'classnames'
import styles from './index.module.scss'

export const RightArrowIcon = (props) => (
  <svg
    className={classNames(styles.svg, props.className)}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.525 13.125L13 10.625C13.1833 10.4417 13.275 10.2333 13.275 10C13.275 9.76667 13.1833 9.55833 13 9.375L10.5 6.85C10.3667 6.71667 10.2 6.654 10 6.662C9.8 6.67067 9.625 6.74167 9.475 6.875C9.34167 7.00833 9.275 7.18333 9.275 7.4C9.275 7.61667 9.34167 7.79167 9.475 7.925L10.8 9.25H6.975C6.775 9.25 6.60433 9.32067 6.463 9.462C6.321 9.604 6.25 9.78333 6.25 10C6.25 10.2167 6.321 10.3957 6.463 10.537C6.60433 10.679 6.78333 10.75 7 10.75H10.8L9.45 12.1C9.31667 12.2333 9.254 12.4 9.262 12.6C9.27067 12.8 9.34167 12.975 9.475 13.125C9.625 13.2583 9.80433 13.325 10.013 13.325C10.221 13.325 10.3917 13.2583 10.525 13.125ZM10 19.5C8.68333 19.5 7.446 19.25 6.288 18.75C5.12933 18.25 4.125 17.575 3.275 16.725C2.425 15.875 1.75 14.8707 1.25 13.712C0.75 12.554 0.5 11.3167 0.5 10C0.5 8.68333 0.75 7.44567 1.25 6.287C1.75 5.129 2.425 4.125 3.275 3.275C4.125 2.425 5.12933 1.75 6.288 1.25C7.446 0.75 8.68333 0.5 10 0.5C11.3167 0.5 12.5543 0.75 13.713 1.25C14.871 1.75 15.875 2.425 16.725 3.275C17.575 4.125 18.25 5.129 18.75 6.287C19.25 7.44567 19.5 8.68333 19.5 10C19.5 11.3167 19.25 12.554 18.75 13.712C18.25 14.8707 17.575 15.875 16.725 16.725C15.875 17.575 14.871 18.25 13.713 18.75C12.5543 19.25 11.3167 19.5 10 19.5ZM10 18C12.2167 18 14.1043 17.221 15.663 15.663C17.221 14.1043 18 12.2167 18 10C18 7.78333 17.221 5.89567 15.663 4.337C14.1043 2.779 12.2167 2 10 2C7.78333 2 5.896 2.779 4.338 4.337C2.77933 5.89567 2 7.78333 2 10C2 12.2167 2.77933 14.1043 4.338 15.663C5.896 17.221 7.78333 18 10 18Z"
      fill="currentColor"
    />
  </svg>
)
