export const getUrlParams = (url) => {
  const params = {}

  if (!url || url.indexOf('?') == -1) {
    return params
  }

  const index = url.indexOf('?')
  const pms = url.substring(index + 1)
  const as = pms.split('&')
  for (let i = 0; i < as.length; i++) {
    const index = as[i].indexOf('=')
    if (index !== -1) {
      try {
        params[as[i].substring(0, index)] = decodeURIComponent(as[i].substring(index + 1))
      } catch (err) {
        params[as[i].substring(0, index)] = as[i].substring(index + 1)
      }
    }
  }
  return params
}
